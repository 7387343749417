<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="searchDates" :cols="3">
          <v-card height="100%">
            <v-card-title>
              Datas:
              <v-spacer></v-spacer>
            </v-card-title>
            <center>
             <v-menu
                ref="menuDataInicio"
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Inicio:
                    {{ moment(String(dataInicio)).format('DD/MM/YYYY') }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataInicio"
                  locale="pt-pt"
                  no-title
                  @input="menuDataInicio = false">
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="menuDataFim"
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Fim:
                    {{ moment(String(dataFim)).format('DD/MM/YYYY')}}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataFim"
                  locale="pt-pt"
                  no-title
                  @input="menuDataFim = false">
                </v-date-picker>
              </v-menu>
            </center>
          </v-card>
        </v-col>
        <v-col key="somaLitros" :cols="3">
          <v-card
            :loading="loaders.totalAbst">
            <v-card-title>
              Total abastecido (Lt):
              <v-spacer></v-spacer>
            </v-card-title>
            <center>
              <h1>{{ (combustiveisTotal * 1).toLocaleString("pt-PT") }}</h1>
            </center>
          </v-card>
        </v-col>
        <v-col key="mediaLitros" :cols="3">
          <v-card
            :loading="loaders.mediaAbs">
            <v-card-title>
              Média abastecimento (Lt):
              <v-spacer></v-spacer>
            </v-card-title>
            <center>
              <h1>
                {{
                  combustiveis.length == 0
                    ? 0
                    : (combustiveisTotal / (combustiveis ? combustiveis.length : 0)).toLocaleString("pt-PT")
                }}
              </h1>
            </center>
          </v-card>
        </v-col>
        <v-col key="emissoes" :cols="3">
          <v-card
            :loading="loaders.mediaAbs">
            <v-card-title>
              Emissões CO2 (g/km):
              <v-spacer></v-spacer>
            </v-card-title>
            <center>
              <h1>
                0
              </h1>
            </center>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col key="hstCamiao" :cols="4">
          <v-card v-if="camioes">
            <v-card-title>
              <v-btn
                title="Selecionar Todos"
                v-if="camioesselected.length == 0"
                class="mx-2"
                fab

                small
                color=primary
                @click="camioesselected = camioes">
                <v-icon>
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-btn>
              <v-btn
              title="Nenhum"
                v-else
                class="mx-2"
                fab

                small
                color=primary
                @click="camioesselected = []">
                <v-icon>
                  mdi-checkbox-multiple-marked
                </v-icon>
              </v-btn>
              Camiões
              <v-btn
                title="Adicionar Camião"
                v-if="permissao()"
                class="mx-2"
                fab

                small
                color=primary
                @click="
                  selecionado = {};
                  addCamiao = true;">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="pesquisaCamiao"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar">
              </v-text-field>
            </v-card-title>
            <v-card-title class="justify-center">
              <span class="mx-4">Mapa</span>
              <v-switch  v-model="showInfo"></v-switch>
              <span class="mx-2">Informações</span>
            </v-card-title>
            <v-data-table
              v-model="camioesselected"
              :headers="camiaoHeader"
              :search="pesquisaCamiao"
              :items="camioes"
              :loading="loaders.camioes"
              loading-text="A carregar..."
              :single-select="false"
              show-select
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.editar`]="{ item }">
                <v-btn
                  v-if="permissao()"
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="editarCamiao(item)">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col v-if="!showInfo" :cols="8">
          <v-card style="height: 650px; width: 100%">
            <v-card-title>
              Mapa
              <v-spacer></v-spacer>
            </v-card-title>
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              v-on:update:zoom="updateZoom($event)"
              ref="mymap">
              <l-geo-json
                v-if="geojson"
                :geojson="geojson"
                :options-style="myStyle">
              </l-geo-json>
              <l-tile-layer :url="url" :attribution="attribution" />
              <template v-if="camioes">
                <l-marker
                  v-for="marker in camioesselected"
                  :key="`camioes-${marker.id}`"
                  :lat-lng="getlatLng(marker.lat, marker.lng)">
                  <l-icon
                    :icon-size="dynamicSizeCamiao"
                    :icon-anchor="dynamicAnchorCamiao"
                    :icon-url="API_URL+'/img/caminhao/normal.png'"/>
                  <l-popup>
                    <div v-if="marker.data_cartrack">
                      Caminhao: {{ marker.matricula }}
                      <br />
                      Última Atualização:
                      {{
                        marker.data_cartrack.split("-")[2].substr(0, 2) +
                        "/" +
                        marker.data_cartrack.split("-")[1] +
                        "/" +
                        marker.data_cartrack.split("-")[0] +
                        " " +
                        marker.data_cartrack.split("-")[2].substr(2, 9)
                      }}
                      <br />
                      <p>
                        <br />
                      </p>
                    </div>
                  </l-popup>
                </l-marker>
              </template>
              <l-marker
                :lat-lng="getlatLng(41.38150949313275, -8.380767029624579)">
                <l-icon
                  :icon-size="dynamicSizeCamiao"
                  :icon-anchor="dynamicAnchorCamiao"
                  :icon-url="API_URL+'/img/resinorte.png'"/>
                <l-popup>
                  <div>Resinorte</div>
                </l-popup>
              </l-marker>
            </l-map>
          </v-card>
        </v-col>
        <v-col key="hstAbastecimento" v-if="showInfo" :cols="4">
          <v-card v-if="combustiveis">
            <v-card-title>Histórico de abastecimentos</v-card-title>
            <v-data-table
              :headers="combustiveisHeader"
              :items="combustiveis"
              :loading="loaders.historicoAbst"
              loading-text="A carregar..."
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:[`item.data_registro`]="{ item }">
                {{ dateToYYYYMMDDHHMM(item.data_registro) }}
              </template>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col key="hstKm" v-if="showInfo" :cols="4">
          <v-card v-if="historicoKm">
            <v-card-title>Histórico de Km</v-card-title>
            <v-data-table
              :headers="historicoKmHeader"
              :items="historicoKm"
              :loading="loaders.historicoKm"
              loading-text="A carregar..."
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:[`item.kms`]="{ item }">
                {{item.kms.find((km) => km.tipo == "final").km - item.kms.find((km) => km.tipo == "inicio").km}}
              </template>
              <template v-slot:[`item.data`]="{ item }">
                {{ dateToYYYYMMDDHHMM(item.data) }}
              </template>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br>
    <div class="text-center">
      <v-dialog v-model="addCamiao" style="z-index: 9999" width="500">
        <v-card :disabled = "loaders.savingCamiao">
          <v-card-title class="headline grey lighten-2" primary-title>
            Camião
          </v-card-title>
          <v-card-text>
            <v-form ref="formCamiao" lazy-validation>
              <v-text-field
                v-model="selecionado.nome"
                label="Nome referência"
                :rules="[rules.required]">
              </v-text-field>
              <v-text-field
                v-model="selecionado.matricula"
                label="Matrícula"
                :rules="[rules.required]">
              </v-text-field>
              <v-text-field
                v-model="selecionado.modelo"
                label="Modelo">
              </v-text-field>
              <v-text-field
                v-model="selecionado.ano"
                label="Ano">
              </v-text-field>
              <v-text-field
                v-model="selecionado.fabricante"
                label="Fabricante">
              </v-text-field>
              <v-text-field
                v-model="selecionado.combustivel"
                label="Combustível">
              </v-text-field>

              <v-btn
                color="success"
                class="mr-4"
                @click="validateForm"
                :loading = "loaders.savingCamiao"
                :disabled = "loaders.savingCamiao">
                Salvar
              </v-btn>

              <v-btn color="error" class="mr-4" @click="addCamiao = false">
                Cancelar
              </v-btn>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import mixin from '../plugins/mixin'
import { icon, latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Report",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LIcon,
    LPolyline,
  },

  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    addCamiao: false,
    pesquisaCamiao: "",
    myStyle: {
      weight: 1,
      color: "#7366aa",
      fillColor: "#92abd2",
    },
    rules: {
      required: (value) => !!value || "Preenchimento obrigatório",
    },
    camiaoHeader: [
      { text: "Matrícula", value: "matricula", align: 'center' },
      { text: "Modelo", value: "modelo", align: 'center' },
      { text: "editar", value: "editar", sortable: true, align: 'center' },
    ],
    camioesselected: [],
    camioes: [],
    combustiveisHeader: [
      { text: "Matrícula", value: "historicocircuito.caminhao.nome", align: 'center' },
      { text: "Km", value: "km", align: 'center' },
      { text: "Litros", value: "quantidade", align: 'center' },
      { text: "Data", value: "data_registro", align: 'center' },
    ],
     historicoKmHeader: [
      { text: "Matricula", value: "matricula", align: 'center' },
      { text: "Rota", value: "rota", align: 'center' },
      { text: "Kms", value: "kms", align: 'center' },
      { text: "Data", value: "data", align: 'center' },
    ],
    dataInicio: null,
    dataFim: new Date().toISOString().substr(0, 10),
    dateFormatted: new Date(),
    menuDataInicio: false,
    menuDataFim: false,
    combustiveis: [],
    historicoKm: [],
    combustiveisRoot: [],
    historicoKmRoot: [],
    combustiveisTotal: 0,
    selecionado: false,
    showInfo: true,
    caminhoes: [],
    loaders: {
      historicoKm: false,
      historicoAbst: false,
      camioes: false,
      totalAbst: false,
      mediaAbs: false,
      savingCamiao: false,
    },
    zoom: 11,
    center: latLng(41.456535, -8.316106, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    geojson: null,
    iconSize: 17,
    iconSizeCamiao: 30,

  }),
  methods: {
    editarCamiao(item) {
      this.selecionado = item;
      this.addCamiao = true;
    },
    validateForm() {
      if (this.$refs.formCamiao.validate()) {
        this.saveCamiao();
      }
    },
    saveCamiao() {
      this.loaders.savingCamiao = true;
      if (this.selecionado.id) {
        this.$http
          .put("camioes/" + this.selecionado.id, this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formCamiao.reset();
            this.getCombustiveis();
            this.addCamiao = false;
            this.loaders.savingCamiao = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingCamiao = false;
          });
      } else {
        this.$http
          .post("camioes/", this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formCamiao.reset();
            this.getCombustiveis();
            this.addCamiao = false;
            this.loaders.savingCamiao = false;
          })
          .catch((error) => {
            this.loaders.savingCamiao = false;
          });
      }
    },
    permissao() {
      return this.getObjeto("user").role.name == "Administrador";
    },

    getHistoricoKm() {
      this.loaders.historicoKm = true;
      var inicio = new Date(this.dataInicio);
      inicio.setDate(inicio.getDate());
      var fim = new Date(this.dataFim);
      fim.setDate(fim.getDate() + 1);

      this.$http
           //.get('graphql?query={kmsConnection(sort:"id:desc",where:{data_registro_gt: "'+inicio.toISOString()+'", \
           .get('graphql?query={kmsConnection(where:{data_registro_gt: "'+inicio.toISOString()+'", \
           data_registro_lte:"'+fim.toISOString()+'",_or:[{tipo:"inicio"},{tipo:"final"}]}) \
           {groupBy{historicocircuito{connection{values{data_registro,km,tipo,historicocircuito \
           {caminhao{id,nome},rota{nome}}}}}}}}',
            {
              headers: {
                Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
        )
        .then((response) => {
          this.historicoKmRoot = [];
          this.historicoKm = response.data.data.kmsConnection.groupBy.historicocircuito;
          this.historicoKm.forEach((hst) => {
            let values = hst.connection.values;
            if(values.length == 2) {
              let hst1 = {tipo: values[0].tipo, km: values[0].km}
              let hst2 = {tipo: values[1].tipo, km: values[1].km}
              try {
                this.historicoKmRoot.push({
                  matricula: values[0].historicocircuito.caminhao.nome,
                  rota: values[0].historicocircuito.rota.nome,
                  kms: [hst1, hst2],
                  data: values[0].data_registro,
                })
              }catch(err){
                console.log(err);
              };
            }
          });
          this.historicoKm = this.historicoKmRoot;
          this.filtroKm();
          this.loaders.historicoKm = false;
        })
        .catch((error) => {
          this.historicoKm = [];
          this.loaders.historicoKm = false;
        });
    },
    getCamioes(){
      this.loaders.camioes = true;
      this.$http
        .get("camioes", {
          headers: { Authorization: `Bearer ${this.getObjeto("token")}`},
        })
        .then((response) => {
          this.camioes = response.data;
          this.loaders.camioes = false;
	      })
        .catch((error) => {
          console.log(error);
          this.loaders.camioes = false;
      });
    },
    getHistoricoCombustiveis(){
      this.loaders.historicoAbst = true;
      var inicio = new Date(this.dataInicio);
      inicio.setDate(inicio.getDate());
      var fim = new Date(this.dataFim);
      fim.setDate(fim.getDate() + 1);
      this.$http
        .get('graphql?query={ \
              	kms(sort:"data_registro:desc", where:{tipo: "combustível", data_registro_gt: "'+inicio.toISOString()+'", data_registro_lte:"'+fim.toISOString()+'"}) \
		            {id, km, quantidade, data_registro, historicocircuito{ caminhao{id, nome} }}}',
            {
              headers: {
                Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
        )
        .then((response) => {
          this.combustiveis = response.data.data.kms;
          this.combustiveisRoot = this.combustiveis;
          this.filtroCombustivel();
          this.loaders.historicoAbst = false;
	      })
        .catch((error) => {
          console.log(error);
          this.loaders.historicoAbst = false;
      });
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
    filtroKm() {
      this.historicoKm = [];
      this.camioesselected.forEach((v) => {
        this.historicoKmRoot.forEach((hr) => {
          if (v.nome == hr.matricula) {
            this.historicoKm.push(hr);
          }
        });
      });
    },
    filtroCombustivel() {
     this.combustiveis = [];
     this.combustiveisTotal = 0;
      this.camioesselected.forEach((v) => {
        this.combustiveisRoot.forEach((cr) => {
          if (v.nome == cr.historicocircuito?.caminhao.nome) {
            this.combustiveis.push(cr);
            this.combustiveisTotal += cr.quantidade;
          }
        });
      });
    },
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
     updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position, zoom = 17) {
      this.zoom = zoom;
      this.zoomUpdate = zoom;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  },
  created() {
    this.getCamioes();
    this.dataInicio = this.subtractDays(30).format('YYYY-MM-DD');
    this.$http
      .get(this.API_URL+"/guimaraes.geojson")
      .then((response) => {
        this.geojson = response.data;
      });
  },
  mounted() {
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

    dynamicSizeCamiao() {
      return [this.iconSizeCamiao, this.iconSizeCamiao * 1.15];
    },
    dynamicAnchorCamiao() {
      return [this.iconSizeCamiao / 2, this.iconSizeCamiao * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    camioesselected: function () {
      this.filtroKm();
      this.filtroCombustivel();
    },
    dataInicio: function () {
      this.getHistoricoKm();
      this.getHistoricoCombustiveis();
    },
    dataFim: function () {
      this.getHistoricoKm();
      this.getHistoricoCombustiveis();
    },
  },
};
</script>
